:root {
  --bs-primary: #0070b8;
  --bs-primary-dark: #003e65;
  --bs-gradient-primary-dark: linear-gradient(90deg,var(--bs-primary) 0%,var(--bs-primary-dark));

  --bs-white-rgb: 255,255,255;
  --bs-secondary-rgb: 240,138,0;
}

.main {
  padding-top: 96px;
  min-height: calc(100vh - 196px);
  &.maintenance-page {
    //background-image: url('/build/images/maintenance/bg-top-page.svg');
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position: top;

    .row>* {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;

      flex-shrink: 0;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x)*0.5);
      padding-left: calc(var(--bs-gutter-x)*0.5);
      margin-top: var(--bs-gutter-y);
    }

    h1 {
      display: inline-block;
      color: var(--bs-primary-dark);
      background: var(--bs-gradient-primary-dark);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(2rem,calc(1.7143rem + 1.4286vw),3rem);
      line-height: 1.33;
      font-weight: 700;
      margin-bottom: 1rem;
      margin-top: 0;
    }

    .news__detail__content {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    .bg-white {
      --bs-bg-opacity: 1;
      background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
    }

    .container__wysiwyg {
      padding: 0 1rem;
    }

    @media (min-width: 992px) {
      .container__wysiwyg {
        padding: 0 3rem;
      }
    }

    .text-huge-2 {
      font-size: 4rem;
    }
    .text-huge-3 {
      font-size: 6rem;
    }
    .text-secondary {
      --bs-text-opacity: 1;
      color: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important;
    }

    .lh-1 {
      line-height: 1!important;
    }
    .fw-normal {
      font-weight: 400!important;
    }
    .fw-bold {
      font-weight: 700!important;
    }

    .text-maintenance {
      color: var(--bs-black)!important;
      font-size: clamp(1.7rem,calc(1.3393rem + .1786vw),1.5rem)!important;

      &.h4 {
        margin-top: 2rem;
        display: block;
        position: relative;
        line-height: 1.2;
        font-weight: 700;
      }
    }
  }
}

.container-maintenance{
  max-width:340px;
}
