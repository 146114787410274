@import '~bootstrap/scss/bootstrap';
@import '~toastr/toastr';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~bootstrap-select/dist/css/bootstrap-select.min.css';
@import "~select2/src/scss/core";
$s2bs5-border-color: $border-color;
@import "~select2-bootstrap-5-theme/src/select2-bootstrap-5-theme";
@import "_maintenance";
@import "carte_grise";

body {
  font-family: aktiv-grotesk, aktiv-grotesk, Helvetica, Roboto, Arial, sans-serif;
}

html, body {
  height: 100%;
  min-height: 100%;
}

h2, h3 {
  margin-bottom: 2rem
}

/* Show it is fixed to the top */
//body {
//    padding-top: 4.5rem;
//}

.hidden {
  display: none;
}

.right {
  text-align: right;
}

.form-inline {
  .input-group > .form-control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex: 1 0 auto;
  }
}
a:not(:hover) {
  text-decoration: none;
}

.main-content {
  margin-top: 4.5rem;

  &.is-logged {
    margin-left: 92px;
  }

  @media (min-width: 992px) {
    &.is-logged {
      margin-left: 250px;
    }
  }
}

body {
  &.anonymous {
    background: white;

    .content-wrapper {
      background: white;

      .content {
        border: none;
      }
    }
  }

  &.is-logged {
    background: #eee;
  }
}

.primary {
  background: #0070B8;
}

.bg-impersonation {
  background-color: #343a40 !important;
  border-color: #343a40;

  .impersonation a {
    color: red;
  }
}

.secondary {
  background: #F08A00;

  &.list-group-item {
    background: #0070B8;
  }
}

.primary-color {
  //color: #0070B8;
}

.secondary-color {
  //color: #F08A00;
  color: #ffffff;
}

.nav-link {
  color: #313234;
}

.list-group-item.secondary .nav-link {
  //background: #F08A00;
  color: #ffffff;
}

.side-menu-top {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  position: fixed;
  //margin-top: -16px;
  padding: 0;
  z-index: 2;
  transition: transform 0.2s;
  height: 100%;
  background: white;
}

.logo-link {
  margin-top: 5px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  &.GTA {
    flex: 1;
  }
  &.RDR {
    flex: 2;
  }
}
h5.groupe-title {
  text-align: center;
  margin-bottom: 16px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &::before, &::after {
    content: "";
    height: 1px;
    background-color: #ccc;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}
label.required:not(.form-check-label):after, legend.required:after {
  content: " *";
  color: red;
}

.w-7 {
  width: 7rem;
}


.w-5 {
  width: 5rem;
}

.w-9 {
  width: 9rem;
}

a.btn.disabled {
  pointer-events: auto;
}

.content-wrapper {
  //margin-left: 92px;
  overflow-x: hidden;
  transition: margin-left 0.2s;
  margin: 20px 30px;

  .content {
    border: 1px solid #dee0e3;
    border-radius: 4px;
    padding: 20px;
    background: white;
  }
}

nav {
  margin-left: 92px;
}

@media (min-width: 992px) {
  .content-wrapper {
    //margin-left:225px;
  }
  .w-lg-9 {
    width: 9rem;
  }
  .custom-nav {
    max-width: 250px;
    width: 100%;

    nav {
      margin-left: 250px !important;
      width: auto;
    }
  }
}

#table_filter {
  text-align: left;
}

.modal-body .nav-tabs {
  margin-bottom: 1rem;
}

.hide {
  display: none;
}

.custom-file-input::before {
  content: 'Select some files';
}

table.dataTable td.custom-fa-icons:before {
  margin: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #ced4da;
  font-style: italic;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #ced4da;
  font-style: italic;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #ced4da;
  font-style: italic;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #ced4da;
  font-style: italic;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #ced4da;
  font-style: italic;
}

/* Microsoft Edge */

@media (min-width: 1500px) {
  .modal-xxl {
    max-width: 1400px;
  }
}


table.dataTable tbody tr.selected {
  background-color: #B0BED9
}

table#factureTable tr {
  cursor: pointer;
}

#loader {
  position: fixed;
  z-index: 9999999;
  height: 120px;
  width: 120px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 225px;
  bottom: 0;
  right: 0;

  display: none;
  border: 16px solid #f3f3f3;
  border-bottom: 16px solid #F08A00;
  border-radius: 50%;
  border-top: 16px solid #0070B8;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;
}

#overlay-loader {
  position: fixed;
  z-index: 9999999;
  height: 120px;
  width: 120px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 92px;
  bottom: 0;
  right: 0;
  border: 16px solid #f3f3f3;
  border-bottom: 16px solid #F08A00;
  border-radius: 50%;
  border-top: 16px solid #0070B8;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@media (min-width: 992px) {
  #overlay-loader {
    left: 225px;
  }
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-file-label::after {
  content: "Parcourir";
}

@media (min-width: 1200px) {
  .w-xl-4 {
    width: 4rem;
  }
}

@media (min-width: 1600px) {
  .w-vl-7 {
    width: 7rem;
  }
  .d-vl-inline {
    display: inline !important;
  }
}

.col-sm-10 .invalid-tooltip {
  left: 15px;
}

fieldset.encadrement-fieldset {
  border: solid #CCCFD1 1px;
  padding: 10px 10px 0 10px;
  /* Margin négatif pour compenser le padding du parent */
  margin-left: -10px;
  margin-bottom: 1rem;

  legend {
    width: auto;
    padding: 0 10px;
  }
}

fieldset {
  .subtitle {
    margin-bottom: 20px;
    color: #39c;
  }
}

.fixed-top {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
}

form {
  .form-check {
    display: inline-block;
    padding: 0 1.25em;
  }
  .readonly-disabled{
    pointer-events: none;
    filter: none;
    opacity: 0.5;
    &  ~ .form-check-label {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.btn {
  padding: 0.175rem 0.55rem;
  margin-left: 3px;
}

$bd-bs-4: #17a2b8;
.btn{
  --bd-bs-4:#17a2b8 ;
}
.btn-info {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-bs-4);
  --bs-btn-border-color: var(--bd-bs-4);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($bd-bs-4, 10%)};
  --bs-btn-hover-border-color: #{shade-color($bd-bs-4, 10%)};
  --bs-btn-focus-shadow-rgb: var(--bd-bs-4-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($bd-bs-4, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-bs-4, 20%)};
}
.bs-actionsbox .btn-group{
  display: flex;
  > button{
    margin: 0.25rem;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-note, .alert-notice {
  @extend .alert-info;
}
ul.list-contrat-produits {
  list-style: none;

  li {
    border: 1px transparent solid;
    display: inline-block;
    width: 25em;
  }
}

.alert-existing-client {
  width: 100%;
  padding: 5px;
  margin-top: 2px;
  font-size: 0.8em;
}

// Formulaire Client

form[name="client"] {
  fieldset.sectionFieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 .5rem;
    > legend {
      width: inherit;
      padding: 0 .5rem;
    }
    fieldset {
      padding: 0 .5rem;
      .col-sm-10 {
        padding-left: 10px;
      }
    }
  }
}

form[name="bien_immo"] {
  legend.label {
    font-size: 1rem;
    padding-left: 0;
  }
  fieldset.sectionFieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem .5rem 0 .5rem;
    margin: 0 0 2rem 0;
  }
  .trashBin {
    margin-top: 2px;
  }
  .sinistresHistotiquesHeader {
    display: none;
    background: #eee;
    margin: 0;
    margin-bottom: .5rem;
  }
  #bien_immo_batimentVide>.form-check:first-child {
    display: none;
  }
}

.form-suiviClientSearch > * {
  margin: .5rem 0;
}
table.dataTable.table-sm.sinistres .sorting::before, table.dataTable.table-sm.sinistres .sorting_asc::before, table.dataTable.table-sm.sinistres .sorting_desc::before,
table.dataTable.table-sm.sinistres .sorting::after, table.dataTable.table-sm.sinistres .sorting_asc::after, table.dataTable.table-sm.sinistres .sorting_desc::after{
  top: 25% ;
}
table.dataTable th {
  vertical-align: middle;
}
.line-height-1{
  line-height: 1;
}
/*
.bootstrap-datetimepicker-widget {
  inset: auto !important;
}*/

.th-etat {
  min-width: 80px;
}

form#charte-form {
  border-left: #007bff 1px solid;
}

:root {
  --shield-border-color: #111;
}

.shield {
  height: 33px;
  width: 33px;
  border: 2px solid var(--shield-border-color);
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
  &.text-success{;
    border-color: $teal-400;
    color: $teal-400;
  }
  &.text-muted{;
    border: none;
  }
}


/* Débug toolbar */
.sf-toolbar-block-veos-infos {
  .sf-toolbar-info-piece {
    b {
      vertical-align: top;
    }

    button {
      padding: 0 8px 0 0;
    }

    span[id^=veos-info-] {
      width: 280px;
      padding: 4px 0;
      word-break: break-word;
      white-space: normal;
      vertical-align: top;
    }
  }
}
$calm-brown: #A77642;
.btn-calm-brown {
  --bs-btn-color: #fff;
  --bs-btn-bg: #A77642;
  --bs-btn-border-color: #A77642;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{shade-color($calm-brown, 10%)};
  --bs-btn-hover-border-color: #{shade-color($calm-brown, 10%)};
  --bs-btn-focus-shadow-rgb: 141, 98, 53;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{shade-color($calm-brown, 20%)};
  --bs-btn-active-border-color: #{shade-color($calm-brown, 20%)};
}

