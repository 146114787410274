.popover {
  max-width: 520px;
}

.CG-background{
  position:relative;
  width:450px;
}

.CG-information:hover {
  cursor: pointer;
}

.CG-information:focus {
  outline: none;
}

.CG-information-fa {
  background-color:#ffffff;
}

/*** ENCADREMENTS ***/
//Paramètres généraux
.CG{
  position: absolute;
  height: 2.6%;
  border: 1px solid red;
  border-radius:2px;
  background-color:rgba(150,0,0,0.1);
}

//Coordonnées
.CG_A {
  top:7.4%;
  left:1.7%;
  width:27%;
}
.CG_B {
  top:7.4%;
  left:29.5%;
  width:28%;
}
.CG_C1 {
  top:9.86%;
  left:1.7%;
  width:16.7%;
}
.CG_D1 {
  top:42.7%;
  left:1.7%;
  width:23%;
}
.CG_D3 {
  top:51.5%;
  left:1.7%;
  width:29.5%;
}
.CG_D21 {
  top:48%;
  left:53.3%;
  width:11.5%;
}
.CG_E {
  top:51.5%;
  left:58.6%;
  width:6.5%;
}
.CG_F2 {
  top:53.7%;
  left:24.7%;
  width:9%;
}
.CG_J {
  top: 59%;
  left: 1.7%;
  width: 23%;
}
.CG_J1 {
  top: 59%;
  left: 24.7%;
  width: 18.5%;
}
.CG_P3 {
  top:63.6%;
  left:47.7%;
  width:14.8%;
}
.CG_P1 {
  top:63.6%;
  left:1.7%;
  width:13.8%;
}
.CG_P6 {
  top:63.6%;
  left:65%;
  width:27.6%;
}
